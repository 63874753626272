import React from 'react'
import '../styles/Footer.css' // Import your CSS file


const Footer = () => {
  return (
    <footer className='footer'>
      <p>
        maintained and developed by gougou
      </p>
    </footer>
  )
}

export default Footer
